<template>
	<!-- 平顶山首页 -->
	<div id="firstPage">
		<div class="pageCont container">
			<div class="contTop">
				<!-- 轮播图  -->
				<div class="lbt fl">
					<el-carousel arrow="never">
						<el-carousel-item v-for="(item,index) in picLists" :key="index">
							<!--              <a :href="item.url" target="_black">-->
							<img
								:src="'pub/img/'+item.pathxname"
								alt
								style="width: 100%;height: 100%"
								@click="urljump(item.url)"
							>
							<div class="img-tit tl" @click="urljump(item.url)">{{ item.title }}</div>
							<!--              </a>-->
						</el-carousel-item>
					</el-carousel>
				</div>
				<!-- tab: 新闻动态  工作动态  政策法规  -->
				<div class="news fl">
					<div
						class="boxList"
						style="display: flex;justify-content: space-around;font-size: 20px;margin-bottom: -10px;"
					>
						<div
							v-for="(item,index) in menu"
							:key="index"
							@click="toggle(item)"
							:class="{active:active==index}"
							class="boxTable"
						>{{ item.name }}
						</div>
					</div>
					<el-divider></el-divider>

					<div class="boxContent">
						<div v-if="active==0">
							<ul class="infoUl">
								<li
									class="infoLi tl"
									v-for="(item,index) in xwzx"
									:key="index"
									@click="jump(xwdt_noticeDetail,item.data_id)"
								>
									<span class="newsInfo">{{ item.title }}</span>
									<span class="date">{{ item.createtime | filterTime }}</span>
								</li>
							</ul>
						</div>
						<div v-else-if="active==1">
							<ul class="infoUl">
								<li
									class="infoLi tl"
									v-for="(item,index) in gzdt"
									:key="index"
									@click="jump(gzdt_noticeDetail,item.data_id)"
								>
									<span class="newsInfo">{{ item.title }}</span>
									<span class="date">{{ item.createtime | filterTime }}</span>
								</li>
							</ul>
						</div>
						<div v-else-if="active==2">
							<ul class="infoUl">
								<li
									class="infoLi tl"
									v-for="(item,index) in zcfg"
									:key="index"
									@click="jump(zcfg_messageDetail,item.data_id)"
								>
									<span class="newsInfo">{{ item.title }}</span>
									<span class="date">{{ item.createtime | filterTime }}</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div class="contCenter">
				<!-- 四大土地交易 -->
				<div class="ggsltj">
					<ul class="ggsltjUl flex justify-between">
						<!-- :style="{ background:colors[index]}" -->
						<li
							class="hover-pointer"
							v-for="(item,index) in lists"
							:key="index"
							:style="{ background:'url('+item.path+')'}"
							@click="jyJump(item.url,index)"
						>
							<!-- <p class="num text-28">{{item.num}}</p> -->
							<p class="text-18 margin-top-xl text-bold">{{ item.title }}</p>
						</li>
					</ul>
				</div>
			</div>
			<div class="contBottom">
				<!-- 公示公告 -->
				<div class="notice fl">
					<vNotice></vNotice>
				</div>
				<!-- 联系我们 -->
				<div class="contactUs fl tl guide margin-top-xs">
					<div class="guide-title margin-bottom">
						<div class="fl text-black text-bold text-18">联系我们</div>
						<div class="fr hover-pointer" @click="jump(toContactUs)">MORE +</div>
					</div>
					<!-- <el-divider></el-divider> -->
					<!-- <img src="@/assets/images/map.jpg" alt> -->
					<div class="map">
						<el-amap vid="amapDemo" :zoom="zoom" :center="center" style="height: 200px;">
							<el-amap-marker
								v-for="item in markers"
								:position="item.position"
								:events="item.events"
								:key="item.index"
							></el-amap-marker>
						</el-amap>
					</div>
					<div class="margin-top-sm margin-bottom-sm">
						<p>{{ telName }}：{{ telVal }}</p>
						<p class="margin-top-xs margin-bottom-xs">{{ emailName }}：{{ emailVal }}</p>
						<p>{{ addressName }}：{{ addressVal }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vNotice from "./children/vNotice";

	export default {
		data() {
			return {
				/*
				 * 跳转页面  parent为路由  child为路由下的子项（0表示没有子项）
				 */

				// 新闻动态  工作动态  政策法规--详情
				xwdt_noticeDetail: {
					parent: "noticeDetail",
					child: "新闻动态"
				},
				gzdt_noticeDetail: {
					parent: "noticeDetail",
					child: "工作动态"
				},
				zcfg_messageDetail: {
					parent: "noticeDetail",
					child: "政策法规"
				},
				// 联系我们
				toContactUs: {
					parent: "contactUs",
					child: "联系我们"
				},

				active: 0,
				menu: [
					{
						name: "新闻中心",
						index: 0,
						value: 0
					},
					{
						name: "工作动态",
						index: 1,
						value: 1
					},
					{
						name: "政策法规",
						index: 2,
						value: 2
					}
				],
				xwzx: [], // 新闻中心
				gzdt: [], // 工作动态
				zcfg: [], // 政策法规
				picLists: [], // 轮播图
				// 土地
				lists: [
					{
						title: "国有建设用地使用权",
						path: require("../../assets/images/bg-1.png")
					},
					{
						title: "集体经营性建设用地交易",
						path: require("../../assets/images/bg-2.png")
					},
					{
						title: "土地二级市场交易",
						path: require("../../assets/images/bg-4.png")
					},
					{
						title: "矿业权交易",
						path: require("../../assets/images/bg-3.png")
					}
				],
				colors: [
					"rgba(236, 128, 141, 1)",
					"rgba(245, 154, 35, 1)",
					"rgba(112, 182, 3, 1)",
					"rgba(28,176,242)"
				],
				info: [], // 联系我们
				telName: "",
				telVal: "",
				emailName: "",
				emailVal: "",
				addressName: "",
				addressVal: "",
				pageSize: 10,

				zoom: 16,
				center: [113.20062, 33.76721],
				markers: [],

				unit: "",
				address: ""
			};
		},
		updated() {
			let boxTable = document.getElementsByClassName("boxTable")[1];
			boxTable.classList.add("newBox");
		},
		mounted() {
			// window.onload = function() {
			let boxTable = document.getElementsByClassName("boxTable")[1];
			boxTable.classList.add("newBox");
			// };
		},
		created() {
			this.clearMapstore();
			this.getData();
			this.initMap();
		},
		methods: {
			// hover切换背景
			changeIndex(index) {
				switch (index) {
					case 1:
						this.leaderPng = this.leaderPngT;
						break;
					case 2:
						this.organizationPng = this.organizationPngT;
						break;
				}
			},
			// 鼠标移除后的changeIndexBack函数如下：
			changeIndexBack(index) {
				switch (index) {
					case 1:
						this.leaderPng = this.leaderPngO;
						break;
					case 2:
						this.organizationPng = this.organizationPngO;
						break;
				}
			},

			//跳转
			jump(val, id) {
				this.$router.push({
					name: val.parent,
					query: {child: val.child, id: id}
				});
			},

			//url 跳转
			urljump(url) {
				window.open(url);
			},

			// 获取数据
			getData() {
				let param = {
					gglx: 1,
					pageSize: this.pageSize
				};

				this.apis.api.getIndexList(param).then(res => {
					if (res.data.code == "01") {
						let datas = res.data.dat[0];
						// 轮播图
						this.picLists = datas.img;

						// 三大新闻
						this.xwzx = datas.xwzx;
						this.gzdt = datas.gzdt;
						this.zcfg = datas.zcfg;

						//四种交易跳转 / 联系我们
						this.info = datas.info;
						this.info.forEach(e => {
							if (e.keyy == "phone") {
								this.telName = e.uname;
								this.telVal = e.valuess;
							}
							if (e.keyy == "email") {
								this.emailName = e.uname;
								this.emailVal = e.valuess;
							}
							if (e.keyy == "address") {
								this.addressName = e.uname;
								this.addressVal = e.valuess;
							}

							//国有
							this.lists[0].url = datas.tradingurl + "/#/?project=gyjy";

							//集体
							this.lists[1].url = datas.tradingurl + "/#/?project=jtjy";

							//二级
							this.lists[2].url = datas.tradingurl + "/secondary/index.html";

							//矿业
							this.lists[3].url = datas.tradingurl + "/#/?project=kyjy";
						});
					}
				});
			},

			// 四种交易跳转
			jyJump(data, index) {
				if (index == 0) {
					window.open('http://td.hnggzy.com/#/trading?branchProject=1')
					// this.$message("敬请期待！");
				} else {
					window.open(data);
				}
			},

			// 地图
			initMap() {
				this.apis.api.getContactUsDatas().then(res => {
					if (res.data.code == "01") {
						let datas = res.data.dat;

						datas.forEach(e => {
							if (e.keyy == "unit") {
								this.unit = e.valuess;
							}

							if (e.keyy == "address") {
								this.address = e.valuess;
							}

							if (e.keyy == "xy") {
								//   // json字符串转数值型数组
								var coord = e.valuess.split(",");
								this.center = coord.map(Number);
								this.markers.push({
									position: coord.map(Number)
								});
							}
						});
					}
				});
			},

			// 切换nav
			toggle(data) {
				this.active = data.index;
			},

			clearMapstore() {
				localStorage.removeItem("_AMap_vectorlayer");
				localStorage.removeItem("_AMap_wgl");
				localStorage.removeItem("_AMap_sync");
				localStorage.removeItem("_AMap_raster");
				localStorage.removeItem("_AMap_overlay");
				localStorage.removeItem("_AMap_mouse");
				localStorage.removeItem("_AMap_AMap.ToolBar");
				localStorage.removeItem("_AMap_AMap.Scale");
				localStorage.removeItem("_AMap_AMap.RangingTool");
				localStorage.removeItem("_AMap_AMap.PolyEditor");
				localStorage.removeItem("_AMap_AMap.PlaceSearch");
				localStorage.removeItem("_AMap_AMap.OverView");
				localStorage.removeItem("_AMap_AMap.MouseTool");
				localStorage.removeItem("_AMap_AMap.MarkerClusterer");
				localStorage.removeItem("_AMap_AMap.MapType");
				localStorage.removeItem("_AMap_AMap.Geolocation");
				localStorage.removeItem("_AMap_AMap.CitySearch");
				localStorage.removeItem("_AMap_AMap.CircleEditor");
				localStorage.removeItem("_AMap_AMap.Autocomplete");
				localStorage.removeItem("_AMap_AMap.IndoorMap3D");
				localStorage.removeItem("_AMap_Map3D");
				localStorage.removeItem("_AMap_labelcanvas");
				localStorage.removeItem("_AMap_labelDir");
				localStorage.removeItem("_AMap_data.tileKeys");
				localStorage.removeItem("_AMap_AMap.CustomLayer");
				localStorage.removeItem("_AMap_AMap.Geocoder");
				localStorage.removeItem("_AMap_AMap.CustomLayer");
				localStorage.removeItem("_AMap_AMap.IndoorMap");
				localStorage.removeItem("_AMap_anole");
				localStorage.removeItem("_AMap_cmng");
				localStorage.removeItem("_AMap_cvector");
			}
		},
		components: {
			vNotice
		}
	};
</script>
<style lang="scss" scoped>
	#firstPage {
		.pageCont {
			padding: 20px;
			box-sizing: border-box;
			background-color: #fff;

			.contTop {
				height: 330px;

				.lbt {
					width: 50%;
					background-color: #ccc;
				}

				.news {
					padding: 10px;
					width: 50%;
					height: 330px;
					overflow: hidden;
					// background-color: skyblue;
				}
			}

			.contCenter {
				padding: 20px 0px;
				box-sizing: border-box;

				.ggsltj {
					.ggsltjUl {
						li {
							width: 275px;
							height: 100px;
							text-align: center;
							color: #fff;
							background-color: #ccc;
							border-radius: 5px;

							&:hover {
								opacity: 0.8;
							}

							&:active {
								opacity: 1;
							}
						}
					}
				}
			}

			.contBottom {
				height: 370px;

				.notice {
					width: calc(100% - 300px);
					height: 370px;
					overflow: hidden;
				}

				.contactUs {
					margin-left: 20px;
					width: 280px;
					height: 100%;

					.map {
						width: 280px;
						height: 200px;
					}

					// 服务指南
					.guide {
						.guide-title {
							height: 46px;
							line-height: 46px;
							border-bottom: 1px solid #ccc;
						}

						.zlxz,
						.jyyj,
						.bzwd {
							width: 100%;
							height: 40px;
							line-height: 40px;
							border-radius: 5px;

							&:hover {
								opacity: 0.8;
							}

							&:active {
								opacity: 1;
							}
						}

						.zlxz {
							background-color: rgba(128, 128, 255, 1);
						}

						.jyyj {
							background-color: rgba(194, 128, 255, 1);
						}

						.bzwd {
							background-color: rgba(132, 0, 255, 1);
						}
					}
				}
			}

			// 切换新闻nav
			.boxList {
				color: #7f7f7f;
				font-weight: bold;

				.boxTable {
					cursor: pointer;
				}

				.boxTable.active {
					color: #00abeb;
				}

				.boxTable:hover {
					color: #00abeb;
				}
			}
		}

		.boxContent {
			margin-top: -10px;
			font-size: 16px;
			box-sizing: border-box;

			.infoUl {
				.infoLi {
					display: flex;
					justify-content: space-between;
					line-height: 36px;
					cursor: pointer;

					&:hover {
						color: #0e83c9;
					}

					.newsInfo {
						width: 430px;
						height: 50px;
						line-height: 50px;
						display: inline-block \0
					;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						-webkit-line-clamp: 1;
						-webkit-box-orient: vertical;
						word-break: break-all; /* 追加这一行代码 */
					}

					.date {
						display: inline-block \0
					;
						margin-top: 10px;
						color: #aaaaaa;

						// &:hover {
						//   color: #0e83c9;
						// }
					}
				}
			}
		}

		.newBox {
			border-left: 1px solid #dcdfe6;
			border-right: 1px solid #dcdfe6;
			padding: 0px 60px;
		}

		.img-tit {
			width: 100%;
			height: 40px;
			background-color: #4f4f4d;
			opacity: 0.5;
			// background-color: rgba($color: #000000, 1);
			color: white;
			line-height: 40px;
			padding-left: 10px;
			position: absolute;
			bottom: 0;
		}

		// 轮播图
		.el-carousel__item h3 {
			color: #475669;
			font-size: 18px;
			opacity: 0.75;
			line-height: 300px;
			margin: 0;
		}

		.el-carousel__item:nth-child(2n) {
			background-color: #99a9bf;
		}

		.el-carousel__item:nth-child(2n + 1) {
			background-color: #d3dce6;
		}

		/deep/ .el-carousel--horizontal .el-carousel__indicators--horizontal {
			left: auto;
			right: -5px;
		}

		/deep/ .el-carousel--horizontal .el-carousel__button {
			width: 10px;
			height: 10px;
			border-radius: 50%;
		}

		/deep/ .pageCont .boxList .boxTable {
			flex: 16% \0
		;
		}

		.newBox {
			padding: 0 10px \0
		;
		}
	}
</style>

