<template>
  <!-- 公示公告 -->
  <div id="notice">
    <div class="notice-top margin-top-xs">
      <div class="icon-skyblue"></div>
      <div class="title fl margin-left-xs tc">公示公告</div>
      <div class="line fl"></div>
      <div class="more fr hover-pointer" @click="jump(toNotice)">MORE +</div>
      <div class="nav">
        <ul class="navUl flex">
          <li
            v-for="(item, index) in navList"
            :key="index"
            class="hover-pointer"
            :class="{ active: isActiveClick == index }"
            @click="toChangeJYLB(index, item.code)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="message">
      <div class="notice-title fl">
        <ul class="titleUl">
          <li
            class="titleLi"
            :class="{ activeBg: activeClick == index }"
            v-for="(item, index) in menulists"
            :key="index"
            @click="toChangeGG(index)"
          >
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="notice-cont fl" v-if="showData" style="min-height:300px;">
        <ul class="contUl tl">
          <li
            class="contLi"
            v-for="(item, index) in lists"
            :key="index"
            @click="lookDetail(item.id, item.zylb)"
          >
            <span class="news text-16"
              >【{{ item.ggbh }}】{{ item.title }}</span
            >
            <span class="date">{{ item.createtime | filterTime }}</span>
          </li>
        </ul>
      </div>
      <div v-else class="notice-cont margin-top" style="min-height:300px;">
        暂无数据！
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      /*
       * 跳转页面  parent为路由  child为路由下的子项（0表示没有子项）
       */

      // 公示公告
      toNotice: {
        parent: "notice",
        child: "公示公告",
      },
      isActiveClick: 0, // tab一级
      activeClick: 0, // tab一级
      navList: [],
      lists: [],
      pageSize: 10,
      menulists: [],
      showData: true,

      code: "",
    };
  },
  created() {
    this.initZDB("zylb");
    this.initZDB("jyfs");
    this.code = 2;
    this.getData(1, 1);
  },
  methods: {
    // 查询字典表--公示公告标题
    initZDB(val) {
      let param = {
        dcode: val,
      };
      this.apis.api.getSerchZDB2(param).then((res) => {
        if (res.data.code == "01") {
          if (val == "zylb") {
            //  tab一级
            let datas = res.data.dat;

            for (var i = 0; i < datas.length; i++) {
              if (datas[i].name == "集体建设用地使用权") {
                datas[i].name = "集体经营性建设用地交易";
              }
              if (datas[i].name == "采矿权") {
                datas.splice(i, 1);
              }

              if (datas[i].name == "探矿权") {
                datas.splice(i, 1);
              }
            }
            for (var k = 0; k < datas.length; k++) {
              if (datas[k].name == "二级市场") {
                datas[k].name = "土地二级市场交易";
              }
            }

            datas.push({
              name: "矿业权交易",
              code: "3,4",
            });
            // datas.unshift({
            //   name: "国有建设土地交易",
            //   code: "1",
            // });

            this.navList = datas;
          } else if (val == "jyfs") {
            //  tab二级
            let datas = res.data.dat;

            for (var z = 0; z < datas.length; z++) {
              // if (datas[z].name == "协议") {
              // 	datas[z].name = "补充公告";
              // }
              // if (datas[z].name == "挂牌") {
              // 	datas[z].name = "挂牌公告";
              // }
              // if (datas[z].name == "拍卖") {
              // 	datas[z].name = "拍卖公告";
              // }
              if (datas[z].code == "-1") {
                datas[z].name = "补充公告";
              }
              if (datas[z].code == "1") {
                datas[z].name = "挂牌公告";
              }
              if (datas[z].code == "2") {
                datas[z].name = "拍卖公告";
              }
              if (datas[z].code == "3") {
                datas[z].name = "协议公告";
              }
            }

						this.menulists = datas;
						this.menulists.push({
              name: "补充公告",
              code: '-1',
            });
          }
        }
      });
    },

    // 获取数据
    getData(val, val2) {
      let param = {
        gglx: val,
        jyfs: val2,
        pageSize: this.pageSize,
      };
      this.apis.api.getIndexNoticeList(param).then((res) => {
        if (res.data.code == "01") {
          let datas = res.data.dat;
          this.lists = datas;

          if (datas == "" || datas == null || datas == undefined) {
            this.showData = false;
          } else {
            this.showData = true;
          }
        }
      });
    },

    // 切换公示公告---类别
    toChangeJYLB(index, code) {
      this.isActiveClick = index;
      this.code = code;
      this.getData(code, 1);
      this.activeClick = 0;
    },

    // 切换公示公告--公告
    toChangeGG(val) {    
      this.activeClick = val;

      let num = val + 1;
      this.getData(this.code, num=='4'?'-1':num);
    },

    //跳转
    jump(val, id) {
      this.$router.push({
        name: val.parent,
        query: { child: val.child, id: id },
      });
    },

    // 跳转到公告详情页面
    lookDetail(item, zylb) {
      this.$router.push({
        name: "noticeDetail",
        query: {
          id: item,
          child: "公示公告",
          path: "/notice",
          zylb: zylb,
          jyfs: this.activeClick + 1,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#notice {
  .notice-top {
    position: relative;
    width: 100%;
    height: 30px;
    line-height: 30px;

    .title {
      width: 96px;
      height: 100%;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      background-color: rgba(2, 167, 240, 1);
    }

    .line {
      margin-top: 15px;
      width: 680px;
      height: 1px;
      border-top: 1px solid #ccc;
    }

    .nav {
      position: absolute;
      top: 0;
      left: 130px;
      width: 500px;
      height: 30px;

      .navUl {
        margin-top: 3px;

        li {
          padding: 0 5px;
          margin-right: 10px;
          height: 23px;
          line-height: 22px;
          box-sizing: border-box;
          text-align: center;
          color: #333333;
          background-color: #fff;
          border-radius: 22px;
          border: 1px solid #ccc;
          white-space: nowrap;

          &:hover {
            color: #fff;
            background-color: rgba(2, 167, 240, 1);
          }

          &:active {
            opacity: 0.8;
          }
        }

        .active {
          color: #fff;
          background-color: rgba(2, 167, 240, 1);
          border: none;
        }
      }
    }
  }

  .message {
    margin-top: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    border: 1px solid #ccc;

    .notice-title {
      width: 180px;
      margin-right: 20px;

      .titleUl {
        .titleLi {
          margin-bottom: 10px;
          width: 175px;
          height: 40px;
          line-height: 40px;
          cursor: pointer;
          background: url("../../../assets/images/gsggbg-2.png") no-repeat;

          &:hover {
            color: #fff;
            background: url("../../../assets/images/gsggbg-1.png") no-repeat;
          }
        }

        .activeBg {
          color: #fff;
          background: url("../../../assets/images/gsggbg-1.png") no-repeat;
        }
      }
    }

    .notice-cont {
      width: calc(100% - 200px);
      // width: 100%;
      .contUl {
        .contLi {
          display: flex;
          justify-content: space-between;
          line-height: 38px;
          font-size: 16px;
          // border-bottom: 1px solid #ccc;
          cursor: pointer;

          &:hover {
            color: #0e83c9;
          }

          .news {
            display: inline-block \0;
            width: 520px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .date {
            display: inline-block \0;
            color: #aaaaaa;
            font-size: 14px;

            &:hover {
              color: #0e83c9;
            }
          }
        }
      }
    }
  }
}
</style>
